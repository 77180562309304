<template>
  <div class="page-container">
    <ErrorBlock />
    <DesignPage />

    <FloatingContainer :bottom="'24px'" :right="'24px'">
      <FloatingButton
        :label="'Annuler'"
        :type="'cancel'"
        :symbol="'close'"
        @click="$router.go(-1)"
      />

      <FloatingButton
        v-if="fieldId"
        class="validate-button"
        :label="'Mettre à jour'"
        :type="'validate'"
        :symbol="'done'"
        @click="update"
      />

      <FloatingButton
        v-else
        class="validate-button"
        :label="'Valider'"
        :type="'validate'"
        :symbol="'done'"
        @click="create"
      />
    </FloatingContainer>
  </div>
</template>

<script>
import DesignPage from "@/components/DesignPage";
import ErrorBlock from "@/components/ErrorBlock";
import FloatingButton from "@/components/basics/FloatingButton";
import FloatingContainer from "@/components/FloatingContainer";

export default {
  name: "defaultCreate",
  components: { DesignPage, FloatingButton, ErrorBlock, FloatingContainer },
  computed: {
    tableName() {
      return this.$route.meta.tableName;
    },
    apiUrl() {
      return this.$route.meta.apiUrl;
    },
    fieldId() {
      return this.$route.params.id;
    },
    getFieldsForQueryParameter() {
      return this.$store.getters["design/getFieldsForQueryParameter"];
    },
    getEmptyFieldsForApi() {
      return this.$store.getters["design/getEmptyFieldsForApi"];
    },
    routeName() {
      return this.$route.name;
    },
    apiIndex() {
      return this.$route.meta.apiIndex;
    },
  },
  methods: {
    getApiData(apiIndex) {
      return this.$store.getters["api/getApiDataByApiIndex"](apiIndex);
    },
    async create() {
      const retValue = await this.$store.dispatch("api/create", {
        tableName: this.tableName,
        url: this.apiUrl,
        data: this.getApiData(this.apiIndex),
      });

      if (retValue !== false) this.$router.go(-1);
    },
    async update() {
      const retValue = await this.$store.dispatch("api/update", {
        tableName: this.tableName,
        url: this.apiUrl,
        data: this.getApiData(this.apiIndex),
        id: this.fieldId,
      });

      if (retValue !== false) this.$router.go(-1);
    },
  },
  async created() {
    await this.$store.dispatch("design/getDesignData", this.routeName);

    await this.$store.dispatch("design/refreshActiveFields");

    if (this.fieldId) {
      await this.$store.dispatch("api/get", {
        url: `${this.apiUrl}/${this.fieldId}`,
        apiIndex: this.apiIndex,
        params: { fields: this.getFieldsForQueryParameter },
      });
    } else {
      this.$store.dispatch("api/setEmptyData", {
        data: this.getEmptyFieldsForApi,
        apiIndex: this.apiIndex,
      });
    }
  },
};
</script>

<style lang="less" scoped>
.validate-button {
  margin-left: 16px;
}
</style>
