<template>
  <div class="error-block" v-if="errors.length">
    <div class="line" v-for="(error, index) in errors" :key="index">
      <span >{{ error.msg }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-block",
  computed: {
    errors() {
      return this.$store.state.api.errors;
    },
  },
};
</script>

<style lang="less" scoped>
.error-block {
  padding: 20px 20px;
  background-color: @error-red;
  color: @white;
  border-radius: 5px;

  .line {
    margin-bottom: 12px;
  }

  .line:last-child {
    margin-bottom: 0;
  }
}
</style>